import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { GatsbyImage } from "gatsby-plugin-image";
import { PLink, Text } from "components";

const BlogItem = ({ item }) => {
  return (
    <div>
      <GatsbyImage
        image={
          item.featuredImage?.node.localFile.childImageSharp.gatsbyImageData
        }
        alt="blog post featured image"
        className="w-full h-[301px]"
      />

      <PLink to={`/blog/${item.slug}`}>
        <Text value={item.title} variant="h4" className={titleStyle} />
      </PLink>
      <p dangerouslySetInnerHTML={{ __html: item.excerpt }} className="py-4" />

      <div className="flex gap-4">
        <Text
          value={item.date}
          variant="p18"
          color="secondary-500"
          weight={700}
        />
        <PLink to={`/blog/${item.slug}`}>
          <Text value="Read more" variant="p18" weight={700} color="primary" />
        </PLink>
      </div>
    </div>
  );
};

const titleStyle = ctl(`
  pt-[41px]
  pb-4
`);

export { BlogItem };
