import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ctl from "@netlify/classnames-template-literals";
import { Container, PLink, Text } from "components";

const LatestPost = ({ blogData }) => {
  return (
    <div className={latestPostStyle}>
      <Container>
        {blogData
          .filter((_, index) => index === 0)
          .map(item => (
            <div key={item.title} className="w-full">
              <GatsbyImage
                image={
                  item.featuredImage?.node.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt="blog post featured image"
                className="w-full max-h-[600px]"
              />
              <Text variant="p14" value="Latest" className={tagStyle} />
              <PLink to={`/blog/${item.slug}`}>
                <Text value={item.title} variant="h3" className={titleStyle} />
              </PLink>
              <p
                dangerouslySetInnerHTML={{ __html: item.excerpt }}
                className={descriptionStyle}
              />

              <div className="flex gap-4">
                <Text
                  value={item.date}
                  variant="p18"
                  color="secondary-500"
                  weight={700}
                />
                <PLink to={`/blog/${item.slug}`}>
                  <Text
                    value="Read more"
                    variant="p18"
                    weight={700}
                    color="primary"
                  />
                </PLink>
              </div>
            </div>
          ))}
      </Container>
    </div>
  );
};

const latestPostStyle = ctl(`
  pt-[100px]
  pb-10
`);
const tagStyle = ctl(`
  pt-[41px]
  pb-4
  uppercase
`);
const titleStyle = ctl(`
  max-w-[789px]
`);
const descriptionStyle = ctl(`
  max-w-[558px]
  py-4
`);

export { LatestPost };
