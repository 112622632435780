import React from "react";

import { Layout } from "components";

import { BlogPageHeader, Blogposts } from "templates/blog";
import { graphql } from "gatsby";
import { BlogCategoryContent } from "templates/blog/blogposts/categories";

export const postsQuery = graphql`
  {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        excerpt
        title
        slug
        date(formatString: "LL")
        content
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  quality: 100
                )
              }
            }
          }
        }
      }
    }

    allWpCategory {
      nodes {
        name
      }
    }
  }
`;

const BlogPage = ({ data }) => {
  const { allWpCategory, allWpPost } = data;
  const blogItems = allWpPost.nodes;
  const categoriesList = allWpCategory.nodes;

  const categories = categoriesList.map(category => {
    const postsForCategory = blogItems.filter(blogItem => {
      const blogItemsCategories = blogItem.categories.nodes.map(
        blogItemCategory => blogItemCategory.name
      );
      return blogItemsCategories.includes(category.name);
    });

    const noOfPosts = postsForCategory.length;

    if (noOfPosts) {
      return {
        title: category.name,
        content: <BlogCategoryContent blogItems={postsForCategory} />,
      };
    } else {
      return {};
    }
  });

  const allCategories = [
    {
      title: "All",
      content: <BlogCategoryContent blogItems={blogItems} />,
    },
    ...categories,
  ];

  return (
    <Layout title="Parkway Blog" theme="light">
      <BlogPageHeader />
      <Blogposts categories={allCategories} />
    </Layout>
  );
};

export default BlogPage;
