import React from "react";
import { Br, PageHeader } from "components";

const BlogPageHeader = () => {
  return (
    <div className="h-[70vh]">
      <PageHeader
        title={
          <>
            BuildZone with <Br on="mobile" /> Parkway
          </>
        }
        subheading={
          <>
            Chatterbox of some of the most interesting <Br on="mobile" />
            happenings in <Br on="desktop" />
            fintech from our own lens
          </>
        }
        center
        theme="light"
      />
    </div>
  );
};

export { BlogPageHeader };
