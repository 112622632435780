import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Container } from "components";
import { LatestPost } from "../latest-post";
import { BlogItem } from "../blog-item";

const BlogCategoryContent = ({ blogItems }) => {
  return (
    <Container>
      <LatestPost blogData={blogItems} />

      <div className={postGridStyle}>
        {blogItems
          .filter((_, index) => index > 0)
          .map(item => (
            <BlogItem key={item.title} item={item} />
          ))}
      </div>
    </Container>
  );
};

const postGridStyle = ctl(`
  grid
  md:grid-cols-2
  lg:grid-cols-3
  gap-x-7
  gap-y-10
  lg:pt-[112px]
  lg:pb-[152px]
  py-[80px]
`);

export { BlogCategoryContent };
