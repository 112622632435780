import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Container, Tabs } from "components";

const Blogposts = ({ categories }) => {
  return (
    <Container>
      <section className={wrapStyle}>
        <Tabs data={categories} light />
      </section>
    </Container>
  );
};

const wrapStyle = ctl(`
  bg-white 
  flex 
  flex-col 
  items-center
`);

export { Blogposts };
